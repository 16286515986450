import { Link, useResolvedPath } from 'react-router-dom';
import logo from '../assets/logo.svg';
import { useState } from 'react';
import axios from 'axios';
import { EMAIL_BACKEND } from '..';
import { Box, Button, Modal, LinearProgress, Typography, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import successImg from '../assets/success.svg';

export default function Footer() {
    const [email, setEmail] = useState("")
    const [success, setSuccess] = useState(true)
    const [failed, setFailed] = useState(false);

    const [openSuccess, setOpenSuccess] = useState(false);
    const handleOpenSuccess = () => setOpenSuccess(true);
    const handleCloseSuccess = () => setOpenSuccess(false);    
    
    const [openFailed, setOpenFailed] = useState(true);
    const handleOpenFailed = () => setOpenFailed(true);
    const handleCloseFailed = () => setOpenFailed(false);
    
    const handleSubmit = (e) => {
        e.preventDefault()
        if (email != "")
            axios.post(`https://crm.nativetalkapp.com:447/flsk/cy-mail`, {
                "full_name": email,
                "to_email": "support@cybervilla.io",
                "email_template": "template1"
            }, {headers: {'Authorization': `919c02f004339822ecb967cc526977b528f7ba55`}})
            .then((res) => {
                setSuccess(true);
                handleOpenSuccess();
                // alert('successful')
            })
            .catch((err) => {console.log(err)})
    }

    return(
        <footer class="footer bg-black w-100 py-md-5 pt-1 px-md-5 px-3 container-fluid border-bottom border-secondary" style={{ paddingBottom: '100px',}}>
            <div class="row py-5 align-items-start justify-content-between">
                <div class="col-md-3 d-flex flex-column ps-md-5">
                    <img src={logo} width="130" classname="footer-logo" alt="logo" loading="lazy"/>
                    <div class="mt-md-4 mt-2 mb-4">
                        <a class="fb py-1 ms-2 ms-md-0 me-4" href="https://www.facebook.com/Cyberstickio-100203956085984/">
                            <img src="https://backend.nativetalkapp.com:455/static/ic_fb.svg" loading="lazy"/>
                        </a>
                        <a class="tw py-1 me-4" href="https://instagram.com/cybervilla.io?igshid=YmMyMTA2M2Y=">
                            <img src="https://backend.nativetalkapp.com:455/static/ic_tw.svg" loading="lazy"/>
                        </a>
                        <a class="ig py-1 me-4" href="https://instagram.com/cybervilla.io?igshid=YmMyMTA2M2Y=">
                            <img src="https://backend.nativetalkapp.com:455/static/ic_ig.svg" loading="lazy"/>
                        </a>
                        <a class="yt py-1 me-4" href="https://youtube.com/channel/UCAvjUB_HpH77JcBvEKfhZFA">
                            <img src="https://backend.nativetalkapp.com:455/static/ic_yt.svg" loading="lazy"/>
                        </a>
                    </div>
                    
                </div>
                <div class="col-md-3 col-6 d-flex flex-column mt-5 mt-md-0">
                    <h5 class="p-0 mt-md-0 text-white">CATEGORIES</h5>
                    <a href="https://www.cybervilla.io/" class="py-1 m-0">CyberBuy</a>
                    <a href="https://www.cybervilla.io/shop" class="py-1 m-0">CyberSwap</a>
                    <Link to="/" className="py-1 m-0">CyberFix</Link>
                </div>
                <div class="col-md-3 col-6 d-flex flex-column mt-5 mt-md-0">
                    <h5 class="p-0 mt-md-0 text-white">CONTACT US</h5>
                    <a href="tel:07002923784552" class="py-1 m-0">0700cybervilla</a>
                    <a href="mailto:support@cybervilla.io" class="py-1 m-0">support@cybervilla.io</a>
                </div>
                <div class="col-md-3 col-6 d-flex flex-column mt-5 mt-md-0">
                    <h5 class="p-0 mt-md-0 text-white">INFORMATION</h5> 
                    <a href="https://www.cybervilla.io/privacypolicy" class="py-1 m-0">Terms And Conditions</a>
                    <a href="https://www.cybervilla.io/privacypolicy" class="py-1 m-0">Privacy Policy</a>
                </div>
            </div>  
            <div class="d-flex text-white align-items-end flex-column mt-md-0">
                <p class="text-end off-white">SUBSCRIBE TO OUR NEWSLETTER</p>
                <form class="d-flex" onSubmit={(e) => handleSubmit(e)}>
                    <div class="form-group me-2 " >
                        <input required onChange={(e) => setEmail(e.target.value)} type="email" class="form-control text-white" placeholder="Your email" style={{backgroundColor: "#222222"}}/>
                    </div>
                    <button class="btn ctabtn ">Subscribe</button>
                </form>
            </div>

            {(() => {
                     if (success) {
                        return (
                            <div>
                                <Modal
                                     open={openSuccess}
                                     onClose={handleCloseSuccess}
                                     aria-labelledby="successfull-subscription"
                                     aria-describedby="successful-subscription"
                                   >
                                    <Box sx={style}>
                                        <Box component="img" src={successImg} height={80} />
                                        <Typography fontWeight="bold" pt={3} id="successful-subscription" variant="h5" component="h2">
                                           Thank You for Subscribing to our Newsletter
                                       </Typography>
                                    </Box>
                                </Modal>

                            </div>

                )
            }
            })()}
            
        </footer>
    )
    
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'black',
    borderRadius: '20px',
    color: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
  };


 //     else if (failed) 
                //         return (
                //             <div>
                //                 {/* <Button onClick={handleOpen}>Open modal</Button> */}
                //                 <Modal
                //                 open={openFailed}
                //                 onClose={handleCloseFailed}
                //                 aria-labelledby="modal-modal-title"
                //                 aria-describedby="modal-modal-description"
                //                 >
                //                 <Box sx={style}>
                //                     <Box component="img" src={successImg} height={80} />
                //                     <Typography fontWeight="bold" pt={3} id="modal-modal-title" variant="h4" component="h2">
                //                         Failed
                //                     </Typography>
                //                     {/* <Typography id="modal-modal-description" mt={1}>
                //                         {}}
                //                     </Typography> */}
                //                     <Button onClick={handleCloseFailed} class="mt-5 ctabtn btn w-100" style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                //                         Try Again
                //                     </Button>
                //                 </Box>
                //                 </Modal>
                //             </div>
                //         )
                // })()}    

           
            
            // })()}